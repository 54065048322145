<template>
  <div>
    <div>
      <div class="select-header">
        <div>
          <div class="_Inp" style="margin-top: 0px">
            <span>文件名：</span>
            <el-input placeholder="请输入文件名" v-model="fileNameInp" @input="changInpVal" @keyup.enter.native="searchList" />
          </div>
          <div class="_Inp" style="margin-top: 0px; margin-right: 10px">
            <span>编号：</span>
            <el-input placeholder="请输入编号" v-model="fileNoInp" @input="changInpVal" @keyup.enter.native="searchList" />
          </div>
          <div class="_Inp" style="margin-top: 0px; margin-right: 10px">
            <span>问题：</span>
            <el-input placeholder="请输入问题" v-model="fileQuestion" @input="changInpVal" @keyup.enter.native="searchList" />
          </div>
          <el-button icon="el-icon-search" @click="searchList"></el-button>
        </div>
        <div>
          <el-button type="primary" @click="addQuestion">添加题库</el-button>
          <el-tooltip class="item" effect="dark" content="导入文件格式为xlsx" placement="top">
            <el-button class="_ImgBtn" type="primary" @click="uploadFile">
              <img src="../../assets/images/import2.png" style="margin: 0" />
            </el-button>
          </el-tooltip>
          <input
            type="file"
            @change="importFile(this)"
            id="imFile"
            ref="fileBtn"
            style="display: none"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <el-button class="_ImgBtn" type="primary" style="margin-left: 10px" @click="exportExcel">
            <img src="../../assets/images/import1.png" style="margin: 0" />
          </el-button>
          <el-button class="_ImgBtn" icon="el-icon-delete" @click="deleteItem"></el-button>
          <el-button class="_ImgBtn" icon="el-icon-edit" @click="editItem"></el-button>
        </div>
      </div>
      <div class="_TableBox">
        <el-table
          :data="questionList"
          style="width: 100%"
          stripe
          border
          :height="elementHeight"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
          @row-click="rowClick"
          id="table"
        >
          <el-table-column type="selection" width="80" v-if="isShow">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked"></el-checkbox>
            </template>
          </el-table-column>
          <!-- 编号-名称 -->
          <!-- <el-table-column prop="name" label="岗位名称" width="180">
          </el-table-column>-->
          <el-table-column prop="fileName" label="文件名称" width="180"></el-table-column>
          <el-table-column prop="fileNo" label="文件编号"></el-table-column>
          <el-table-column prop="question" label="问题描述"></el-table-column>
          <el-table-column prop="answer" label="标准答案"></el-table-column>
          <el-table-column prop="lawAsk" label="法律法规要求"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 30, 50, 80]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listCount"
          class="_Pagination"
        ></el-pagination>
      </div>
    </div>
    <Dialog
      :title="title"
      :showTowText="showBtnText"
      width="50%"
      top="15vh"
      :showDialog="showDialog"
      class="dialog"
      @handleReqData="handleReqData"
      @handleClose="handleClose"
    >
      <div class="contaion">
        <div>
          <el-form ref="form" label-width="100px">
            <el-form-item label="文件编号：">
              <el-input placeholder="请输入文件编号" v-model="fileNo"></el-input>
            </el-form-item>
            <el-form-item label="文件名称：">
              <el-input placeholder="请输入文件名称" v-model="fileName"></el-input>
            </el-form-item>
            <el-form-item label="问题描述：">
              <el-input placeholder="请输入问题" v-model="question"></el-input>
            </el-form-item>
            <el-form-item label="涉及范畴：">
              <el-select v-model="auditTypeName" collapse-tags multiple placeholder="请选择">
                <el-option
                  v-for="item in option1"
                  :key="item.value"
                  :label="item.auditTypeName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="涉及岗位：">
              <el-select v-model="postName" collapse-tags multiple placeholder="请选择">
                <el-option
                  v-for="item in option3"
                  :key="item.value"
                  :label="item.postName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-form ref="form" label-width="120px">
            <el-form-item label="标准答案：">
              <el-input placeholder="请输入标准答案" v-model="answer"></el-input>
            </el-form-item>
            <el-form-item label="法律法规要求：">
              <el-input placeholder="请输入法律法规要求" v-model="lawAsk"></el-input>
            </el-form-item>
            <!-- <el-form-item label="风险描述：">
              <el-input placeholder="请输入问题" v-model="question"></el-input>
            </el-form-item>-->
            <el-form-item label="涉及区域：">
              <el-select v-model="areaName" collapse-tags multiple placeholder="请选择">
                <el-option
                  v-for="item in option2"
                  :key="item.value"
                  :label="item.areaName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <!-- <el-upload
          class="avatar-uploader"
          :action="uploadExcel()"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :headers="token"
          :before-upload="beforeAvatarUpload"
          :on-change="getfile"
        >
          <img
            :src="item"
            v-for="(item, index) in imageUrl"
            :key="index"
            class="avatar"
          />
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>-->
      </div>
    </Dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, put, baseURL } from "../../api/http";
import Dialog from "../../components/dialog/Dialog.vue";
import { exportExcel } from "../../modules/ExportExcel";
import XLSX from "xlsx";
import { saveAs } from "file-saver";

export default {
  components: { Dialog },
  data() {
    return {
      elementHeight: 0,
      BUrl: baseURL[process.env.NODE_ENV + ""],
      token: {
        Authorization:
          "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
      },
      showDialog: false,
      fileNameInp: "",
      imageUrl: [],
      isAdd: true,
      showBtnText: false,
      rowItem: {},
      tableRowIndex: null,
      importDataList: [],
      // 搜索
      fileNoInp: "",
      fileQuestion:"",
      questionList: [],
      isSearch: false,
      // 参数
      fileNo: "",
      fileName: "",
      question: "",
      answer: "",
      imageAddr: "",
      lawAsk: "",
      // 分页
      currentPage: 1,
      pageSize: 20,
      listCount: 0,
      //
      auditTypeName: [],
      areaName: [],
      postName: [],
      option1: [],
      option2: [],
      option3: [],
      isShow: true,
      title:'',
      countNum:0,
    };
  },
  mounted() {
    this.getQuestionList();
    this.getdate();
    this.imFile = document.getElementById("imFile");
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop + 134);
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getQuestionList();
    },
    rowClick(row) {
      this.rowItem = row;
      this.tableRowIndex = row.index;
      this.questionList.map((item) => {
        item.checked = false;
        if (row.id == item.id) {
          row.checked = true;
        }
      });
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    getdate() {
      get("/api/AuditType/GetList").then((res) => {
        this.option1 = res.data;
      });
      get("/api/Area/GetList").then((res) => {
        this.option2 = res.data;
      });
      get("/api/Post/GetList").then((res) => {
        this.option3 = res.data;
      });
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    changInpVal() { 
      if (this.fileNoInp == "" && this.fileNameInp == "" && this.fileQuestion == "") {
        this.isSearch = false;
        this.getQuestionList();
      }
    },
    // changQuestion(){
    //   let list = this.questionList.filter(item => {
    //       return item.question.indexOf(this.fileQuestion) !== -1;
    //     });
    //   this.questionList = list;
    // },
    handleClose() {
      this.showDialog = false;
      this.imageUrl = [];
    },
    searchList() {
      if (this.fileNoInp == "" && this.fileNameInp == "" && this.fileQuestion == "") return;
      this.isSearch = true;
      this.currentPage = 1;
      this.getQuestionList();
    },
    getQuestionList() {
      get("/api/QuestionPool/GetByFilePage?FileNo=" +
          this.fileNoInp +
          "&FileName=" +
          this.fileNameInp +
          "&question=" +
          this.fileQuestion +
          "&limit=" +
          this.pageSize +
          "&page=" +
          this.currentPage).then((resp) => {
            if (resp.code == 200) {
              this.questionList = resp.data;
              this.listCount = resp.count;
              this.rowItem = {};
              this.tableRowIndex = null;
            }
          }).then(() =>{
            var url = this.isSearch
              ? "/api/NewQuestionPool/GetByFile?FileNo=" +
                this.fileNoInp +
                "&FileName=" +
                this.fileNameInp 
                
              : "/api/NewQuestionPool/GetListPage?page=" +
                this.currentPage +
                "&limit=" +
                this.pageSize;
            get(url).then((resp) => {
              if (resp.code == 200) {
                this.countNum = resp.count;
                this.questionList.forEach(item =>{
                  resp.data.forEach(i =>{
                    if(item.id == i.id){
                      item.questionAndArea = i.questionAndArea
                      item.questionAndAuditType = i.questionAndAuditType
                      item.questionAndFunction = i.questionAndFunction
                      // item = i
                    }
                    // console.log(this.questionList);
                  })
                })
                // this.questionList = resp.data;
                // this.listCount = resp.count;
                // this.rowItem = {};
                // this.tableRowIndex = null;
              }
            });
          })
    },
    // 上传图片
    beforeAvatarUpload(file) {
      if (file.type === "image/png" || file.type === "image/jpeg") {
        console.log("等于", file.type);
      } else {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
    },
    uploadExcel() {
      let url = this.BUrl + "/api/UpLoadFiles/UploadImg";
      return url;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl.push(URL.createObjectURL(file.raw));
    },
    getfile(file) {
      if (file.response == undefined) return;
      if (this.imageAddr == "") {
        this.imageAddr = file.response.value; //图片上传到服务器，请求后返回的图片路径
      } else {
        this.imageAddr = this.imageAddr + "," + file.response.value;
      }
    },
    // 增删改
    handleReqData() {
      var data = {
        id: this.isAdd ? 0 : this.rowItem.id,
        fileNo: this.fileNo,
        fileName: this.fileName,
        question: this.question,
        answer: this.answer,
        imageAddr: this.imageAddr,
        lawAsk: this.lawAsk,
        isDelete: 0,
        saveTime: this.isAdd ? new Date().toISOString() : this.rowItem.saveTime,
        saveUserId: JSON.parse(sessionStorage.getItem("userInfo")).id + "",
        questionAndArea: this.areaName.toString(),
        questionAndFunction: this.postName.toString(),
        questionAndAuditType:this.auditTypeName.toString(),
      };
      var methods = this.isAdd ? post : put;
      methods("/api/NewQuestionPool", data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: this.isAdd ? "添加成功" : "修改成功",
            });
            this.showDialog = false;
            if(this.isAdd){
              this.isSearch = false;
              this.getQuestionList();
            }else{
              this.isSearch = true;
              this.getQuestionList()
            }
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? "添加失败" : "修改失败");
        });
    },
    addQuestion() {
      this.showDialog = true;
      this.title = '添加题库'
      this.isAdd = true;
      this.showBtnText = false;
      this.fileNo = "";
      this.fileName = "";
      this.question = "";
      this.answer = "";
      this.imageAddr = "";
      this.lawAsk = "";
      this.auditTypeName = [];
      this.postName = [];
      this.areaName = [];
    },
    editItem() {
      if (this.tableRowIndex == null) return;
      this.imageUrl = [];
      this.isAdd = false;
      this.showBtnText = true;
      this.showDialog = true;
      this.title = '修改题库'
      this.answer = this.rowItem.answer;
      this.fileNo = this.rowItem.fileNo;
      this.fileName = this.rowItem.fileName;
      this.question = this.rowItem.question;
      this.imageAddr = this.rowItem.imageAddr;
      this.lawAsk = this.rowItem.lawAsk;
      this.auditTypeName = this.rowItem.questionAndAuditType ? this.rowItem.questionAndAuditType.split(",").map(Number) : [];
      this.postName = this.rowItem.questionAndFunction ? this.rowItem.questionAndFunction.split(",").map(Number) : [];
      this.areaName = this.rowItem.questionAndArea ? this.rowItem.questionAndArea.split(",").map(Number) : [];
      console.log(this.auditTypeName);
      if (this.imageAddr == "") return;
      var imgList = this.imageAddr.split(",");
      imgList.map((item) => {
        this.imageUrl.push(this.BUrl + "/uploads/" + item);
      });
    },
    deleteItem() {
      if (this.tableRowIndex == null) return;
      this.$confirm("您确认要删除“" + this.rowItem.question + "”这个问题？")
        .then(() => {
          deleteMethod("/api/QuestionPool?Id=" + this.rowItem.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.questionList.splice(this.tableRowIndex, 1);
                this.tableRowIndex = null;
                this.rowItem = {};
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    // 导入excel
    uploadFile: function () {
      this.$confirm("是否需要标准格式文件?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          // 创建工作簿
          const workbook = XLSX.utils.book_new();
          // 创建工作表
          const header = ["公司", "部门", "岗位","职能","文件编号","文件名称","问题描述","标准答案"];
          const worksheet = XLSX.utils.aoa_to_sheet([header]); // 使用aoa_to_sheet创建带有表头的工作表
          // 将工作表添加到工作簿中
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          // 将工作簿转换为二进制数据流
          const data = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          // 创建一个Blob对象并设置数据流
          const blob = new Blob([data], { type: "application/octet-stream" });
          // 使用file-saver保存文件
          saveAs(blob, "标准格式文件.xlsx");
        })
        .catch((action) => {
          if(action === 'cancel'){
            // 点击导入按钮
            this.imFile.click();
          }
        });
    },
    importFile: function () {
      var importDataList = [];
      let obj = this.imFile;
      if (!obj.files) {
        return;
      }
      var f = obj.files[0];
      var reader = new FileReader();
      let $this = this;
      reader.onload = function (e) {
        var data = e.target.result;
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: "base64",
          });
        } else {
          $this.wb = XLSX.read(data, {
            type: "binary",
          });
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        );
        excelData.map((item) => {
          importDataList.push({
            id: 0,
            CompName: item.公司,
            DeptName: item.部门,
            PostName: item.岗位,
            FunctionName: item.职能,
            fileName: item.文件名称,
            fileNo: item.文件编号,
            answer: item.标准答案,
            // lawAsk: item.法律法规要求,
            question: item.问题描述,
            imageAddr: "",
            isDelete: 0,
            saveTime: new Date().toISOString(),
            saveUserId: JSON.parse(sessionStorage.getItem("userInfo")).id + "",
          });
        });
        if (importDataList.length != 0) {
          $this.importQuestion(importDataList);
        } else {
          $this.$message({
            type: "warning",
            message: "导入文件不符合",
          });
          this.$refs.fileBtn.value = null
        }
      };
      if (this.rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    importQuestion(list) {
      post("/api/QuestionPool/BatchAdd", {
        data: JSON.stringify(list),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "导入成功",
            });
            this.getQuestionList();
            this.$refs.fileBtn.value = null
          } else {
            this.$message.error(resp.message);
            this.$refs.fileBtn.value = null
          }
        })
        .catch(() => {
          this.$message.error("导入失败");
        });
    },
    // 导出
    exportExcel() {
      this.isShow = false;
      if(!this.isShow){
        this.$nextTick(async () => {
          await exportExcel("#table", "题库维护.xlsx");
          this.isShow =  true;
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .dialog .el-form-item__label {
  padding: 0 !important;
}
.dialog .el-select {
  width: 100%;
}
.dialog .contaion > div:nth-of-type(1) {
  display: flex;
}
.dialog .contaion > div:nth-of-type(1) div {
  padding-right: 30px;
}
.select-header {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  > div {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
}
/* 上传图片 */
.avatar-uploader {
  margin-left: 38px;
  padding-right: 30px;
  padding-top: 20px;
}
/deep/ .avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #b4b3b3;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px dotted #b4b3b3;
  border-radius: 2px;
  margin-top: 4px;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
  margin-right: 4px;
  margin-top: 4px;
}
</style>
